import Vue from "vue"
import Vuex from "vuex"
import api from "../api"
import * as types from "./mutation-types"

Vue.use(Vuex)

const handle_response_meta = (commit, response) => {
    if(response && response.meta && response.meta.updates) {
        Object.keys(response.meta.updates).forEach(updated_entity => {
            const updated_records = response.meta.updates[updated_entity]
            commit(types.SET_RECORDS, { entity: updated_entity, records: updated_records.data || updated_records })
        })
    }
    if(response && response.meta && response.meta.forget) {
        Object.keys(response.meta.forget).forEach(forget_entity => {
            response.meta.forget[forget_entity].forEach(id => {
                commit(types.UNSET_RECORD, { entity: forget_entity, record_id: id })
            })
        })
    }
    if(response && response.app_context) {
        commit(types.SET_APP_CONTEXT, { app_context: response.app_context })
    }
    receive_heartbeat_data(response)
    if(!!response && !!response.awards && !!response.awards.length) commit(types.SET_NEW_AWARDS, { awards: response.awards })
}
const handle_error = (error, commit, silent) => {
    const status = standardized_error_status(error)
    if(status === 401) commit(types.SET_PROFILE, { profile: null })
    else if(!silent && (status !== 200)) throw standard_error(error)
}
const standardized_error_status = (error) => {
    if(error) {
        let status = error.status
        if(error.response && error.response.status) status = error.response.status
        if((status === 401) || (status === 402) || (status === 403) || (status === 418) || (status === 422)) return status
    }
    return 200
}
const standard_error = (call_results, raw) => {
    const result = {
        error: {
            header: "",
            message: "",
            number: 0
        }
    }

    if(call_results) {
        if(call_results.response && call_results.response.data && call_results.response.data.response_data && call_results.response.data.response_data.error) {
            result.error.message = call_results.response.data.response_data.error
        } else if(call_results.data && call_results.data.response_data && call_results.data.response_data.error) {
            result.error = call_results.data.response_data.error
        } else if(call_results.data && call_results.data.response_data && call_results.data.response_data.message) {
            result.error.message = call_results.data.response_data.message
        } else if(call_results.error) {
            result.error.message = call_results.error
        } else if(call_results.message) {
            result.error.message = call_results.message
        } else if(call_results.response_data && call_results.response_data.message) {
            result.error.message = call_results.response_data.message
        } else if(call_results.data && call_results.data.error) {
            result.error = call_results.data.error
        } else {
            result.error = call_results.data || call_results
        }
        if(typeof result.error === "string") {
            result.error = {
                header: window.nibnut.vue.translate("Ooops!"),
                message: result.error,
                number: 0
            }
        }

        if(call_results.status) result.error.number = call_results.status
        else if(call_results.error && call_results.error.number) result.error.number = call_results.error.number
        else if(call_results.number) result.error.number = call_results.number
    }

    return raw ? result : result.error
}

const state = {
    ip: "",
    offline: !navigator.onLine,
    app_context: {},
    award_board: {
        last_seen_at: null,
        awards: []
    },
    maintenance: false,
    profile_id: null, // current logged-in user
    login_request: {
        panel_id: false,
        callback: null
    },
    system_message: {
        type: "primary", // primary, success, warning or error
        message: "",
        dismiss_after: 7000, // in milliseconds
        message_id: null
    },
    archiving_students: null, // null is "off", true is automated, false is manual
    managing_students_list: null, // null is "off", true is automated, false is manual
    last_system_message: "",
    route_states: {},
    history: [],

    // this is our records cache records in here can be basic, or fully-loaded.
    records: {
        attachment: {/* { [id: number]: Attachment } */},
        tag: {/* { [id: number]: Tag } */},
        user: {/* { [id: number]: User } */},
        visit: {/* { [id: number]: Visit } */},
        asset_visit: {/* { [id: number]: AssetVisit } */},
        plan: {/* { [id: number]: Plan } */},
        coupon: {/* { [id: number]: Coupon } */},
        tax_rate: {/* { [id: number]: TaxRate } */},
        license: {/* { [id: number]: License } */},
        curriculum: {/* { [id: number]: Curriculum } */},
        curriculum_item: {/* { [id: number]: CurriculumItem } */},
        asset: {/* { [id: number]: Asset } */},
        glossary_term: {/* { [id: number]: GlossaryTerm } */},
        asset_grade: {/* { [id: number]: AssetGrade } */},
        asset_grade_curriculum_item: {/* { [id: number]: AssetGradeCurriculumItem } */},
        asset_user: {/* { [id: number]: AssetUser } */},
        action_log: {/* { [id: number]: ActionLog } */},
        badge: {/* { [id: number]: Badge } */},
        award: {/* { [id: number]: Award } */},
        institution: {/* { [id: number]: Institution } */},
        group: {/* { [id: number]: Group } */},
        group_user: {/* { [id: number]: GroupUser } */},
        subject_user: {/* { [id: number]: SubjectUser } */},
        assignment: {/* { [id: number]: Assignment } */},
        question: {/* { [id: number]: Question } */},
        answer: {/* { [id: number]: Answer } */},
        usage_statistic: {/* { [id: number]: UsageStatistic } */},
        setting: {/* { 0: Editable Settings } */},
        lockdown: {/* { [id: number]: Lockdown } */},
        secret: {/* { [id: number]: Secret } */},
        field_translation: {/* { [id: number]: FieldTranslation } */}
    }
}

const getters = {
    route_state_by_identifier: (state) => (identifier) => {
        return state.route_states[identifier]
    },
    entity_records: (state) => (entity, ids = null) => {
        if(state.records[entity]) {
            if(!ids) return Object.values(state.records[entity])
            return ids.map(id => state.records[entity][id]).filter(record => !!record)
        }
        return []
    },
    entity_record: (state) => (entity, id) => {
        if(state.records[entity] && state.records[entity][id]) return state.records[entity][id]
        return null
    },
    history_back_info: (state) => () => {
        if(state.history.length < 2) return { id: "", title: "" }
        return state.history[state.history.length - 2]
    }
}

const actions = {
    EVALUATE_ONLINE_STATUS ({ state }) {
        state.offline = !navigator.onLine
    },

    HISTORY_PUSH ({ commit }, { title }) {
        const id = window.location.pathname
        commit(types.HISTORY_PUSH, { id, title })
    },
    HISTORY_POP ({ commit }, { all = false }) {
        commit(types.HISTORY_POP, { all })
    },

    REQUEST_LOGIN ({ state }, { panel_id = true, callback = null }) {
        state.login_request.panel_id = panel_id
        state.login_request.callback = callback
    },
    UNREQUEST_LOGIN ({ state }) {
        if(!!state.login_request && !!state.login_request.callback) state.login_request.callback()
        state.login_request.panel_id = false
        state.login_request.callback = null
    },
    SYSTEM_MESSAGE ({ state }, { message, type = "primary", dismiss_after = 7000, message_id = null }) {
        state.system_message.type = type
        state.system_message.message = message
        state.system_message.dismiss_after = dismiss_after
        state.system_message.message_id = message_id
        state.last_system_message = message
    },
    SET_LANGUAGE ({ commit }, { language }) {
        return api.localize(language).then(response => {
            if(!!response.data && !!response.data.id) commit(types.SET_RECORD, { entity: "user", record: response.data })
            handle_response_meta(commit, response)
        })
    },

    SHOW_STUDENT_ARCHIVER ({ state }, { show = false }) {
        state.archiving_students = show
    },
    SHOW_STUDENT_LIST_EDITOR ({ state }, { show = false }) {
        state.managing_students_list = show
    },

    SET_ROUTE_STATE: ({ commit }, { route, route_state }) => {
        commit(types.SET_ROUTE_STATE, { route, route_state })
    },
    JANITOR: ({ commit }, { entities }) => {
        entities.forEach(entity => {
            commit(types.UNSET_RECORDS, { entity })
        })
    },
    SWEEP: ({ commit }, { entity, id }) => {
        commit(types.UNSET_RECORD, { entity, record_id: id })
    },

    LOAD_PROFILE ({ state, commit }) {
        if(state.maintenance) return Promise.reject(new Error("Maintenance"))

        return api.load_profile().then(response => {
            commit(types.SET_PROFILE, { profile: response.data })
            handle_response_meta(commit, response)
        }).catch(error => {
            commit(types.SET_PROFILE, { profile: null })
            handle_response_meta(commit, error.data || error)
        })
    },
    GROUP_LOGIN ({ commit, state }, { access_code }) {
        if(state.maintenance) return Promise.reject(new Error("Maintenance"))

        return api.group_login(access_code).then(response => {
            handle_response_meta(commit, response)
        }).catch(error => {
            throw standard_error(error)
        })
    },
    GROUP_LOGOUT ({ commit }) {
        if(state.maintenance) return Promise.reject(new Error("Maintenance"))

        return api.group_logout().then(response => {
            handle_response_meta(commit, response)
        }).catch(error => {
            throw standard_error(error)
        })
    },
    STUDENT_LOGIN ({ commit, state }, { student_id, username = "", password = "" }) {
        if(state.maintenance) return Promise.reject(new Error("Maintenance"))

        return api.student_login(student_id, username, password).then(response => {
            commit(types.SET_PROFILE, { profile: response.data })
            handle_response_meta(commit, response)
        }).catch(error => {
            commit(types.SET_PROFILE, { profile: null })
            throw standard_error(error)
        })
    },
    STUDENT_LOGOUT ({ commit, state }, { student_id }) {
        if(state.maintenance) return Promise.reject(new Error("Maintenance"))

        return api.student_logout(student_id).then(response => {
            handle_response_meta(commit, response)
        }).catch(error => {
            throw standard_error(error)
        })
    },
    LOGIN ({ commit, state }, { email, password }) {
        if(state.maintenance) return Promise.reject(new Error("Maintenance"))

        return api.login(email, password).then(response => {
            commit(types.SET_PROFILE, { profile: response.data })
            handle_response_meta(commit, response)
        }).catch(error => {
            commit(types.SET_PROFILE, { profile: null })
            throw standard_error(error)
        })
    },
    LOGOUT ({ commit, state }) {
        if(state.maintenance) return Promise.reject(new Error("Maintenance"))

        return api.logout().then(response => {
            commit(types.SET_PROFILE, { profile: null })
            handle_response_meta(commit, response)
        }).catch(error => {
            commit(types.SET_PROFILE, { profile: null })
            throw standard_error(error)
        })
    },
    SEND_PASSWORD_RESET ({ commit, state }, { email }) {
        if(state.maintenance) return Promise.reject(new Error("Maintenance"))

        return api.send_password_reset(email).then(response => {
            return response.data || response
        }).catch(error => {
            throw standard_error(error)
        })
    },
    SIGNUP ({ commit, state }, { data, login = true }) {
        if(state.maintenance) return Promise.reject(new Error("Maintenance"))

        return api.signup(data).then(response => {
            commit(types.SET_PROFILE, { profile: login ? response.data : null })
            handle_response_meta(commit, response)
            return null
        }).catch(error => {
            commit(types.SET_PROFILE, { profile: null })
            throw standard_error(error)
        })
    },
    REINVITE ({ commit }, { email, expires_in }) {
        return api.reinvite(email, expires_in).then((response) => {
            if(response.data) commit(types.SET_RECORD, { entity: "user", record: response.data })
            handle_response_meta(commit, response)
            return response
        }).catch(error => {
            throw standard_error(error)
        })
    },
    LOAD_INVITATION ({ commit }, { token }) {
        return api.invitation(token).then((response) => {
            return response.data || response
        }).catch(error => {
            throw standard_error(error)
        })
    },
    RESET_PASSWORD ({ commit, state }, { token, email, password, password_confirmation }) {
        if(state.maintenance) return Promise.reject(new Error("Maintenance"))

        return api.reset_pasword(token, email, password, password_confirmation).then(response => {
            if(response && response.data && response.data.user) commit(types.SET_PROFILE, { profile: response.data.user })
            return response.data
        }).catch(error => {
            throw standard_error(error)
        })
    },
    FETCH_RECORDS: ({ state, commit }, { entity, query }) => {
        if(state.maintenance) return Promise.reject(new Error("Maintenance"))

        return api.records(entity, query).then(response => {
            const local_response = { total: 0, found: 0, record_ids: [] }

            const records = (response.data || response).map(record => record.data || record)
            commit(types.SET_RECORDS, { entity, records })
            handle_response_meta(commit, response)

            local_response.record_ids = records.map(record => record.id)
            if(response.meta) {
                local_response.total = response.meta.total || 0
                local_response.found = response.meta.found || 0
                local_response._meta = response.meta
            }
            return local_response
        }).catch(error => {
            handle_error(error, commit)
        })
    },
    RECORDS_ACTION ({ state, commit }, { entity, action, data, passthru = false, return_raw = false, method = "get" }) {
        if(state.maintenance) return Promise.reject(new Error("Maintenance"))

        return api.records_action(entity, action, data, method).then((response) => {
            if(!passthru) {
                if(response.data) {
                    const records = (response.data || response).map(record => record.data || record)
                    commit(types.SET_RECORDS, { entity, records })
                }
            }
            handle_response_meta(commit, response)
            return return_raw ? response : response.data
        }).catch((error) => {
            handle_error(error, commit)
        })
    },
    FETCH_RECORD: ({ state, commit }, { entity, id, query }) => {
        if(state.maintenance) return Promise.reject(new Error("Maintenance"))

        return api.record(entity, id, query).then(response => {
            commit(types.SET_RECORD, { entity, record: response.data })
            handle_response_meta(commit, response)
            return response.data
        }).catch((error) => {
            handle_error(error, commit)
        })
    },
    FETCH_RECORD_SHELL: ({ state, commit }, { entity, data, reset = false }) => {
        if(state.maintenance) return Promise.reject(new Error("Maintenance"))

        return api.record_shell(entity, data).then(response => {
            return response.data
        }).catch((error) => {
            handle_error(error, commit)
        })
    },
    CREATE_RECORD: ({ state, commit }, { entity, data }) => {
        if(state.maintenance) return Promise.reject(new Error("Maintenance"))

        return api.record_create(entity, data).then(response => {
            if(response) {
                if(response.data) commit(types.SET_RECORD, { entity, record: response.data })
                handle_response_meta(commit, response)
                return response.data
            }
        }).catch(error => {
            handle_error(error, commit)
        })
    },
    RECORD_SAVE ({ commit, state }, { entity, id, data }) {
        if(state.maintenance) return Promise.reject(new Error("Maintenance"))

        return api.record_save(entity, id, data).then((response) => {
            if(response) {
                if(response.data) commit(types.SET_RECORD, { entity, record: response.data })
                handle_response_meta(commit, response)
                return response.data
            }
        }).catch((error) => {
            handle_error(error, commit)
        })
    },
    RECORD_DELETE: ({ commit, state }, { entity, id, data, unset = true }) => {
        if(state.maintenance) return Promise.reject(new Error("Maintenance"))

        return api.record_delete(entity, id, data).then((response) => {
            if(unset && (!response.data || !response.data.deleted_at)) commit(types.UNSET_RECORD, { entity, record_id: id })
            else if(response.data) commit(types.SET_RECORD, { entity, record: response.data })
            if((entity === "user") && (id === state.profile_id)) commit(types.SET_PROFILE, { profile: null })
            handle_response_meta(commit, response)
            return response.data
        }).catch((error) => {
            handle_error(error, commit)
        })
    },
    RECORD_BULK_DELETE: ({ commit, state }, { entity, ids, data }) => {
        if(state.maintenance) return Promise.reject(new Error("Maintenance"))

        return api.record_bulk_delete(entity, ids, data).then((response) => {
            handle_response_meta(commit, response)
            return ids
        }).catch((error) => {
            handle_error(error, commit)
        })
    },
    RECORD_RESTORE: ({ commit, state }, { entity, id }) => {
        if(state.maintenance) return Promise.reject(new Error("Maintenance"))

        return api.record_restore(entity, id).then((response) => {
            if(response.data && !response.data.deleted_at) commit(types.SET_RECORD, { entity, record: response.data })
            handle_response_meta(commit, response)
            return response.data
        }).catch((error) => {
            handle_error(error, commit)
        })
    },
    RECORD_ACTION ({ state, commit }, { entity, id, action, data, passthru = false, method = "get" }) {
        if(state.maintenance) return Promise.reject(new Error("Maintenance"))

        return api.record_action(entity, id, action, data, method).then((response) => {
            if(!passthru) {
                if(response.data) commit(types.SET_RECORD, { entity, record: response.data })
                handle_response_meta(commit, response)
            }
            return response.data
        }).catch((error) => {
            handle_error(error, commit)
        })
    },
    FILE_ACTION ({ commit }, { entity, id, action, name, file, progress, data, method = "post" }) {
        return api.file_action(entity, id, action, name, file, progress, data, method).then((response) => {
            if(response.data) commit(types.SET_RECORD, { entity, record: response.data })
            handle_response_meta(commit, response)
            return response.data
        }).catch((error) => {
            handle_error(error, commit)
        })
    },
    AUTOSUGGEST: ({ commit, state }, { entity, context, data, passthru = true }) => {
        if(state.maintenance) return Promise.reject(new Error("Maintenance"))

        return api.autosuggest(entity, context, data).then(response => {
            if(!passthru) {
                const records = (response.data || response).map(record => record.data || record)
                commit(types.SET_RECORDS, { entity, records })
                return records.map(record => record.id)
            }
            return response.data
        }).catch(error => {
            handle_error(error, commit)
        })
    },
    REBATE ({ commit, state }, { code, passthru = true }) {
        if(state.maintenance) return Promise.reject(new Error("Maintenance"))

        return api.rebate(code).then(response => {
            if(!passthru && response.data) commit(types.SET_RECORD, { entity: "coupon", record: response.data })
            return response.data
        }).catch(error => {
            throw standard_error(error)
        })
    },
    FETCH_REMOTE_DATA ({ state }, { url }) {
        if(state.maintenance) return Promise.reject(new Error("Maintenance"))

        return api.fetch_remote(url).then(response => {
            return response
        }).catch(error => {
            throw standard_error(error)
        })
    }
}

const mutations = {
    [types.SET_APP_CONTEXT] (state, { app_context }) {
        Vue.set(state, "app_context", app_context || {})
    },
    [types.SET_NEW_AWARDS] (state, { awards }) {
        awards.forEach(award => {
            if(!state.award_board.last_seen_at || (state.award_board.last_seen_at < award.awarded_at)) {
                if(!award.id || !state.award_board.awards.find(known => known.id === award.id)) {
                    state.award_board.awards.push(award)
                    state.award_board.last_seen_at = award.awarded_at
                }
            }
        })
    },
    [types.SET_ROUTE_STATE]: (state, { route, route_state }) => {
        Vue.set(state.route_states, route, route_state)
    },

    [types.SET_PROFILE] (state, { profile }) {
        if(profile) {
            mutations[types.SET_RECORD](state, { entity: "user", record: profile })
            state.profile_id = profile.id

            if(profile.happy_birthday) mutations[types.SET_NEW_AWARDS](state, { awards: [{ id: 0, badge_id: 0, owner_type: "", owner_id: 0, awarded_at: profile.happy_birthday }] })
        } else if(state.profile_id) {
            const user_id = state.profile_id
            state.profile_id = 0
            mutations[types.UNSET_RECORD](state, { entity: "user", record_id: user_id })
        }
    },

    [types.SET_RECORDS]: (state, { entity, records }) => {
        if(state.records[entity] && records) {
            records.forEach(record => {
                mutations[types.SET_RECORD](state, { entity, record })
            })
        }
    },
    [types.UNSET_RECORDS]: (state, { entity, ids = null }) => {
        if(state.records[entity]) {
            if(ids) {
                ids.forEach(id => {
                    mutations[types.UNSET_RECORD](state, { entity, record_id: id })
                })
            } else if(entity !== "tag") {
                let records = {}
                if(state.profile_id) {
                    if(entity === "user") {
                        const profile = state.records.user[state.profile_id]
                        if(profile) records = { [profile.id]: profile }
                    } else if((entity === "group_user") || (entity === "license")) {
                        records = {}
                        Object.values(state.records[entity]).forEach(record => {
                            if(record.user_id === state.profile_id) records[record.id] = record
                        })
                    }
                }
                Vue.set(state.records, entity, records)
            }
        }
    },
    [types.SET_RECORD]: (state, { entity, record }) => {
        record = record.data || record
        const data = {
            ...(state.records[entity][record.id] || {}),
            ...record
        }
        Vue.set(state.records[entity], record.id, data)
    },
    [types.UNSET_RECORD]: (state, { entity, record_id }) => {
        if((entity === "user") && (record_id === state.profile_id)) return // Never remove current user from cache
        Vue.delete(state.records[entity], record_id)
    },
    [types.SET_EDITED_RECORD]: (state, { entity, record_id }) => {
        state.current_entity = entity
        state.edited_record_id = record_id
    },

    [types.HISTORY_PUSH]: (state, { id, title }) => {
        if(!state.history.length || (state.history[state.history.length - 1].id !== id)) state.history.push({ id, title })
        else Vue.set(state.history[state.history.length - 1], "title", title)
    },
    [types.HISTORY_POP]: (state, { all }) => {
        if(all) state.history.splice(0, state.history.length)
        else if(state.history.length) state.history.pop()
    }
}

const receive_heartbeat_data = (data) => {
    if(data.heartbeat_data) {
        state.maintenance = data.heartbeat_data.maintenance || false
        state.ip = data.heartbeat_data.ip || ""
    }

    if(!!data.awards && !!data.awards.length) {
        mutations[types.SET_NEW_AWARDS](state, { awards: data.awards })
    }
}

let heartbeat = null
api.heartbeat_handler(data => {
    if(heartbeat) {
        clearTimeout(heartbeat)
        heartbeat = null
    }

    receive_heartbeat_data(data)

    heartbeat = setTimeout(() => {
        api.heartbeat().catch((error) => {
            if(standardized_error_status(error) === 401) mutations[types.SET_PROFILE](state, { profile: null })
        })
    }, 1000 * (state.maintenance ? 5 : 15))
})

export default new Vuex.Store({
    state,
    getters,
    actions,
    mutations,
    modules: {
        // users
    }
})
