const routes = [
    { path: "/", name: "home", component: require("@/views/dashboard/Dashboard").default, meta: { login_required: true } },
    { path: "/settings", name: "settings", component: require("@/views/Settings").default, meta: { admin_route: true } },
    { path: "/profile", name: "profile", component: require("@/views/user/Edit").default, meta: { login_required: true } },

    { path: "/secret/:id", name: "secret", component: require("@/views/Secret").default, meta: { login_required: false } },

    { path: "/users", name: "user.list", component: require("@/views/user/List").default, meta: { admin_route: true, scroll_position: true } },
    { path: "/user/:id?", name: "user.edit", component: require("@/views/user/Edit").default, meta: { admin_route: true } },

    { path: "/plans", name: "plan.list", component: require("@/views/plan/List").default, meta: { admin_route: true, scroll_position: true } },
    { path: "/plan/:id?", name: "plan.edit", component: require("@/views/plan/Edit").default, meta: { admin_route: true } },
    { path: "/coupons", name: "coupon.list", component: require("@/views/coupon/List").default, meta: { admin_route: true, scroll_position: true } },
    { path: "/coupon/:id?", name: "coupon.edit", component: require("@/views/coupon/Edit").default, meta: { admin_route: true } },

    { path: "/activities", name: "activity.list", component: require("@/views/activity/List").default, meta: { login_required: true, scroll_position: true } },
    { path: "/activity/:id?", name: "activity.edit", component: require("@/views/activity/Edit").default, meta: { admin_route: true } },

    { path: "/resources", name: "resource.list", component: require("@/views/resource/List").default, meta: { login_required: true, scroll_position: true } },
    { path: "/resource/:id?", name: "resource.edit", component: require("@/views/resource/Edit").default, meta: { admin_route: true } },
    { path: "/tools/:id", name: "resource.tool", component: require("@/views/resource/Tool").default, meta: { login_required: true, full_screen: true } },

    { path: "/stats/:group_id/:user_id?", name: "stats", component: require("@/views/stats/View").default, meta: { login_required: true } },
    { path: "/troubleshoot/:group_id/:user_id?", name: "troubleshoot", component: require("@/views/stats/Troubleshoot").default, meta: { login_required: true } },

    { path: "/groups", name: "group.list", component: require("@/views/group/List").default, meta: { login_required: true, scroll_position: true } },
    { path: "/group/:id?", name: "group.edit", component: require("@/views/group/Edit").default, meta: { login_required: true } },
    { path: "/group/:id/access-cards", name: "group.access-cards", component: require("@/views/group/AccessCards").default, meta: { login_required: true } },
    { path: "/students", name: "student.list", component: require("@/views/user/StudentsList").default, meta: { login_required: true, scroll_position: true } },
    { path: "/student/:id?", name: "student.edit", component: require("@/views/user/StudentEdit").default, meta: { login_required: true } },
    { path: "/student/:id/score-card", name: "student.score-card", component: require("@/views/user/StudentScoreCard").default, meta: { login_required: true } },

    {
        path: "/learn",
        name: "learn.home",
        component: require("@/views/students/Dashboard").default,
        meta: { login_required: true, student_route: true }
    },
    {
        path: "/learn/videos",
        name: "learn.videos",
        component: require("@/views/students/Videos").default,
        meta: { login_required: true, student_route: true }
    },
    {
        path: "/learn/glossaries",
        name: "learn.glossaries",
        component: require("@/views/students/Glossaries").default,
        meta: { login_required: true, student_route: true }
    },
    {
        path: "/learn/glossary/:id",
        name: "learn.glossary",
        component: require("@/views/students/Glossary").default,
        meta: { login_required: true, student_route: true }
    },
    {
        path: "/learn/game/:id",
        name: "learn.game",
        component: require("@/views/students/Game").default,
        meta: { login_required: true, student_route: true, full_screen: true }
    },
    {
        path: "/learn/profile",
        name: "learn.profile",
        component: require("@/views/students/Profile").default,
        meta: { login_required: true, student_route: true }
    }
]

export default routes
